<template>
  <div
    class="tooltip"
    :class="`tooltip--${this.position}`"
  >
    <slot></slot>
  </div>
</template>

<script>
// = mixins
import { detectDevice } from '@/assets/js/mixins/base/DetectDevice'

export default {
  name: 'Tooltip',

  props: {
    position: {
      type: String,
      default: 'right'
    }
  },

  mixins: [
    detectDevice
  ],

  watch: {
    device_width () {
      this.$nextTick(() => {
        this.dimensions(this.parent)
      })
    }
  },

  computed: {
    parent () {
      return this.$el.parentNode
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.dimensions(this.parent)
    })
  },

  beforeDestroy () {
    this.$nextTick(() => {
      this.$el.removeAttribute('style')
    })
  },

  methods: {
    dimensions (el) {
      if (this.device_width > 992) {
        let di = el.getBoundingClientRect()
        let width = this.$el.clientWidth

        this.$el.style.top = (di.y + (di.height / 7)) + 'px'

        if (this.position === 'right') {
          this.$el.style.left = (di.left + di.width + 15) + 'px'
        }

        if (this.position === 'left') {
          this.$el.style.left = (di.left - width - 15) + 'px'
        }
      }
    }
  }
}
</script>
